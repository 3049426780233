<template>
  <div class="page__content theme--normal" id="content">
    <div class="page__content__block">
      <div class="notFound">
        <h1>
          404
          <strong>
            Resource Not Found
          </strong>
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "notFound"
  }
</script>

<style scoped>
  .notFound {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
</style>
