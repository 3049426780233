<template>
  <div class="page__content theme--normal" id="content">
    <div class="page__content__block">
      <vPublications></vPublications>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Publications',
  }
</script>

<style scoped>
  .publications {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    min-width: 100%;
    height: 100%
  }
  .list {
    max-width: 38rem;
  }
  .addButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
</style>
