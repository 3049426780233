<template>
  <div class="page__content theme--normal" id="content">
    <div class="page__content__block">
      <div class="about">
        <div class="pageTexts">
          <vDocument
            collectionName="pageTexts"
            documentName="About_the_project"></vDocument>
          <vDocument
            collectionName="pageTexts"
            documentName="About_the_people_title"></vDocument>
        </div>
        <vPeople filePath="aboutUs/people.json"></vPeople>
      </div>
    </div>
  </div>
</template>

<script>
  import vPeople from '../vPeople'
  export default {
    name: "About",
    components: {
      vPeople
    },
  }
</script>

<style scoped>
  .about {
    height: 100%;
    max-width: 900px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .pageTexts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
</style>
