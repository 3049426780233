<template>
  <div class="page__content theme--normal" id="content">
    <div class="page__content__block">
      <div class="report">

        <div class="versionBar">

          <div v-if="document">
            release:
            <vDocumentVersionsDropdown
                :collectionName="collectionName"
                :documentId="document.id"
                :currentVersionName="version.name"
            />
          </div>

          <vNameNewItem
              v-if="user && version"
              buttonLabel="Clone as new Release"
              :reservedNames="reservedNames"
              :takenNames="versionNames"
              collectionName="reports"
              :documentName="documentName"
              :versionAlias="versionAlias"
              @onValidName="createVersion"
          ></vNameNewItem>

        </div>

        <vDocument
          collectionName="reports"
          :documentName="documentName"
          :versionAlias="versionAlias"
        ></vDocument>

      </div>
    </div>
  </div>
</template>

<script>
  import DocumentCollectionsMixin        from "@/mixins/documentCollections/DocumentCollectionsMixin"
  import vNameNewItem                    from "@/components/DocumentCollection/vNameNewItem"
  import vDocumentVersionsDropdown       from "@/components/DocumentCollection/reports/vDocumentVersionsDropdown"
  import { CREATE_NEW_DOCUMENT_VERSION } from "@/store/graph/types"
  import { byDescendingVersion }         from "@/components/DocumentCollection/lib/sortFunctions"

  export default {
    name: "Report",
    components: {
      vDocumentVersionsDropdown,
      vNameNewItem,
    },
    mixins: [
      DocumentCollectionsMixin,
    ],
    props: {
      collectionName: {
        type: String,
        required: true,
      },
      documentName: {
        type: String,
        required: true
      },
      versionAlias: {
        type: String,
        default: 'latest',
      },
    },
    data() {
      return {
        reservedNames: [
          'latest'
        ]
      }
    },
    computed: {
      latestReportVersion() {
        const versions = this.$store.state.reports
            .map(report => report.version)
            .sort(byDescendingVersion)
        let latestVersion
        if (versions.length) {
          latestVersion = versions[versions.length - 1]
        }
        return latestVersion
      },
      reportId() {
        const version = this.version ?? this.latestReportVersion
        const report = this.$store.state.reports.find(report => {
          const isInReportSeries = report.name === this.name
          const isRequestedVersion = report.version === version
          return isInReportSeries && isRequestedVersion
        })
        return report?.id
      }
    },
    mounted() {
      this.loadDocument({
        collectionName: this.collectionName,
        documentName: this.documentName,
        refresh: true
      })
    },
    methods: {
      createVersion(name) {
        if (!name) throw 'version.name is required'
        this.$store.dispatch(CREATE_NEW_DOCUMENT_VERSION, {
              name,
              conten: this.content,
              documentId: this.version.documentId,
              versionId: this.version.id,
            })
            .then(() => {
              this.versionAlias = 'latest'
            })
            .then(() => this.$router.push(`/reports/${this.documentName}`))

      },
    }
  }
</script>

<style scoped>
  .report {
    max-width: 1400px;
  }
  .versionBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
