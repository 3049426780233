<template>
  <div class="page__content theme--normal" id="content">
    <div class="page__content__block">
      <div class="news">
        <div
            v-if="user"
            class="flex-c center">
          <div
              @click="createNewsItem"
              class="labeledButton">
            <div class="icon icon6x">
              <NewspaperPlusIcon
                :size="48"
                style="font-size: 48px!important;"
                title="Add a Person"
              ></NewspaperPlusIcon>
            </div>
            <span>
              Add a News Item
            </span>
          </div>
        </div>
        <div
          v-if="documents.length"
          class="feed"
        >
          <vDocument
            v-for="(article, index) in news"
            :key="index"
            :collectionName="collectionName"
            :documentId="article.id"
          ></vDocument>
        </div>
        <p
          v-else
          class="icon2x"
        >
          No news here yet...
        </p>

      </div>
    </div>
  </div>
</template>

<script>
  import DocumentCollectionsMixin from "@/mixins/documentCollections/DocumentCollectionsMixin"
  export default {
    name: "News",
    mixins: [
      DocumentCollectionsMixin
    ],
    props: {
      collectionName: {
        type: String,
        default: 'news'
      }
    },
    computed: {
      news: {
        get() {
          return this.reverseSortedDocuments
        }
      },
      defaultContent() {
        return `# News Item ${this.documents.length + 1}`
      }
    },
    mounted() {
      this.loadLatestDocumentVersions(this.collectionName)
    },
    methods: {
      createNewsItem() {
        const timestamp = Date.now()
        this.createDocument({
          name: `News Item (${timestamp}})`
        })
      }
    }
  }
</script>

<style scoped>
  .news {
    height: 100%;
    max-width: 900px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .feed .post {
    max-width: 38rem;
    margin-block-end: 1em;
  }
</style>
