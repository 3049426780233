<template>
  <div class="page__content theme--normal" id="content">
    <div class="page__content__block">
      <component
        :is="componentName"
        :collectionName="collectionName"
        :documentName="documentName"
      ></component>
    </div>
  </div>
</template>

<script>
  import DocumentCollectionsMixin from '../../mixins/documentCollections/DocumentCollectionsMixin'
  export default {
    name: "Document",
    mixins: [
      DocumentCollectionsMixin,
    ],
    data() {
      return {
        collectionNameComponentNameMap: {
          publications: 'vAPACitation',
          people: 'vPerson',
        }
      }
    },
    computed: {
      componentName() {
        let componentName
        const document = this.document
        if (document) {
          if (document.type === 'json') {
            componentName = this.collectionNameComponentNameMap[this.document.collectionName]
          }
        }
        return componentName ?? 'vDocument'
      }
    }

  }
</script>

<style scoped>

</style>
