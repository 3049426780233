<template>
  <select
      v-if="options && options.length"
      class="reportHistory"
      v-model="currentValue"
      @change="openReportVersion"
  >
    <option disabled value="">Please select one</option>
    <option
        v-for="option in options"
        :value="option.value"
        :key="option"
    >
      {{option.text}}
    </option>
  </select>
</template>

<script>
import DocumentCollectionsMixin from "@/mixins/documentCollections/DocumentCollectionsMixin"
import { byAscendingVersion }   from "@/components/DocumentCollection/lib/sortFunctions"
export default {
  name: "vDocumentVersionsDropdown",
  mixins: [
      DocumentCollectionsMixin,
  ],
  props: {
    currentVersionName: {
      type: String,
    }
  },
  data() {
    return {
      selected: null
    }
  },
  computed: {
    currentValue() {
      const versionName = this.selected ?? this.currentVersionName
      return this.getVersionUrl(versionName)
    },
    options () {
      const list = this.versionHistory.map(version => {
        return {
          text: `${version.name}`,
          value: this.getVersionUrl(version.name)
        }
      })
      list.reverse()
      return list
    },
    versionHistory() {
      let versions = Object.values(this.$store.state.versions)
        .filter(version => version.collectionName === this.collectionName)
        .filter(version => version.documentId === this.documentId)
        .sort(byAscendingVersion)

      return versions
    },
  },
  methods: {
    getVersionUrl(versionName) { return `/${ this.collectionName }/${ this.document.name }/versions/${ versionName }` },
    openReportVersion(event) {
      const reportUrl = event.target.value
      this.$router.push(reportUrl)
    }
  }
}
</script>

<style scoped>

</style>
