<template>
  <div class="page__content theme--normal" id="content">
    <div class="page__content__block">
      <div
          v-if="collection"
          class="collectionEditor flex-c center document">
        <h1 v-if="user">Documents in the collection <strong>"{{collectionName}}"</strong></h1>
        <vCollection :collectionName="collectionName"></vCollection>
        <div v-if="!documents.length">
          <a class="deleteCollection" @click="deleteEmptyCollection">Delete empty collection "{{ collectionName }}"</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DocumentCollectionMixin                 from "../../mixins/documentCollections/CollectionMixin"
  import { DELETE_COLLECTION } from "@/store/graph/types"

  export default {
    name: "Collection",
    mixins: [
      DocumentCollectionMixin,
    ],
    data() {
      return {
        showMdIntro: false
      }
    },
    computed: {
      collection() {
        return Object.values(this.$store.state.collections).find(c => c.name === this.collectionName)
      }
    },
    mounted() {
      if (!this.collection) {
        this.$router.push({name: 'notFound'})
      }
    },
    methods: {
      deleteEmptyCollection() {
        this.$store.dispatch(DELETE_COLLECTION, { collectionName: this.collectionName})
        .then(() => {
          this.$router.push('/documents')
        })
      }
    }
  }
</script>

<style scoped>
  .collectionEditor {
    text-align: left;
  }
  .item:not(:last-child) {
    margin-bottom: 2px;
  }
</style>
