<template>
  <div class="page__content theme--normal" id="content">
    <div class="page__content__block">
      <vCollections></vCollections>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Collections",
  }
</script>

<style scoped>

</style>
